<template>
  <div class="guide">
    <el-button size="small" type="info" class="right-menu-item hover-effect" icon="el-icon-guide"
      @click="handleGuide">{{
        $t("common.guide") }}</el-button>
    <el-dialog class="cotent-dialog" :visible.sync="dialogVisible" width="750px" :title="$t('common.guide')" top="5vh">
      <el-carousel indicator-position="outside" :loop="false" height="1030px" trigger="click" arrow="always"
        :autoplay="false">
        <el-carousel-item v-for="item in guideImages" :key="item">
          <el-image :src="item" />
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>

<script>
import { ossRoot } from "@/utils/server";
export default {
  data() {
    return {
      dialogVisible: false,
      guideImages: [],
    };
  },
  methods: {
    handleGuide() {
      let route = this.$route.path;
      if (!route) {
        return;
      }
      let path, num;
      if (route.startsWith("/services/data-center/sales")) {
        path = "/common/guide/sales/";
        num = 2;
      } else if (route.startsWith("/services/data-center/stock")) {
        path = "/common/guide/stock/";
        num = 2;
      } else if (route.startsWith("/services/analysis/best10")) {
        path = "/common/guide/best20/";
        num = 2;
      } else if (route.startsWith("/services/analysis/best20-images")) {
        path = "/common/guide/best20-images/";
        num = 2;
      } else if (route.startsWith("/services/analysis/tps")) {
        path = "/common/guide/tps/";
        num = 4;
      } else if (route.startsWith("/services/analysis/matrix")) {
        path = "/common/guide/matrix/";
        num = 4;
      } else if (route.startsWith("/services/data-center/image-data")) {
        path = "/common/guide/image-download/";
        num = 6;
      } else if (route.startsWith("/services/report/monthly-report")) {
        path = "/common/guide/monthly-report/";
        num = 2;
      } else if (route.startsWith("/services/analysis/rising-product")) {
        path = "/common/guide/rising-product/";
        num = 2;
      } else if (route.startsWith("/services/analysis/sales-item")) {
        path = "/common/guide/sales-item/";
        num = 2;
      } else if (route.startsWith("/services/analysis/specific-item")) {
        path = "/common/guide/specific-item/";
        num = 2;
      } else if (route.startsWith("/services/data-center/weekly-board")) {
        path = "/common/guide/weekly-board/";
        num = 2;
      } else if (route.startsWith("/services/visual/street-fashion")) {
        path = "/common/guide/street-fashion/";
        num = 2;
      } else if (route.startsWith("/services/visual/street-trend")) {
        path = "/common/guide/street-trend/";
        num = 2;
      } else if (route.startsWith("/services/visual/window-fashion")) {
        path = "/common/guide/window-fashion/";
        num = 2;
      } else if (route.startsWith("/services/visual/window-trend")) {
        path = "/common/guide/window-trend/";
        num = 2;
      } else if (route.startsWith("/services/analysis/new-product")) {
        path = "/common/guide/new-product/";
        num = 2;
      } else if (route.startsWith("/services/analysis/product-detail")) {
        path = "/common/guide/product-detail/";
        num = 2;
      } else if (route.startsWith("/services/analysis/sales-trend")) {
        path = "/common/guide/sales-trend/";
        num = 2;
      } else if (route.startsWith("/services/analysis/weekly-sales")) {
        path = "/common/guide/weekly-sales/";
        num = 2;
      } else if (route.startsWith("/services/analysis/channel")) {
        path = "/common/guide/channel/";
        num = 2;
      } else if (route.startsWith("/services/analysis/place")) {
        path = "/common/guide/place/";
        num = 2;
      } else if (route.startsWith("/services/analysis/concept")) {
        path = "/common/guide/concept/";
        num = 2;
      } else if (route.startsWith("/services/visual/global-trend")) {
        path = "/common/guide/global-trend/";
        num = 2;
      } else if (route.startsWith("/services/analysis/style-search")) {
        path = "/common/guide/style-search/";
        num = 2;
      } else if (route.startsWith("/services/nlp/search-word")) {
        path = "/common/guide/search-word/";
        num = 4;
      } else if (route.startsWith("/services/nlp/product-name")) {
        path = "/common/guide/product-name/";
        num = 4;
      } else if (route.startsWith("/services/nlp/popular-elements")) {
        path = "/common/guide/popular-elements/";
        num = 4;
      } else if (route.startsWith("/services/nlp/product-reviews")) {
        path = "/common/guide/product-reviews/";
        num = 4;
      } else if (route.startsWith("/services/nlp/sns")) {
        path = "/common/guide/sns/";
        num = 4;
      } else if (route.startsWith("/services/report/color-report")) {
        path = "/common/guide/color-report/";
        num = 2;
      } else if (route.startsWith("/services/report/consulting-report")) {
        path = "/common/guide/consulting-report/";
        num = 2;
      } else if (route.startsWith("/services/report/global-trend-report")) {
        path = "/common/guide/global-trend-report/";
        num = 2;
      } else if (route.startsWith("/services/report/image-trend-map")) {
        path = "/common/guide/image-trend-map/";
        num = 2;
      } else if (route.startsWith("/services/report/item-report")) {
        path = "/common/guide/item-report/";
        num = 2;
      } else if (route.startsWith("/services/report/price-report")) {
        path = "/common/guide/price-report/";
        num = 2;
      } else if (route.startsWith("/services/report/textile-report")) {
        path = "/common/guide/textile-report/";
        num = 2;
      } else if (route.startsWith("/services/gpt/")) {
        path = "/common/guide/model-gpt/";
        num = 4;
      } else if (route.startsWith("/services/gpt-designer/")) {
        path = "/common/guide/ai-design/";
        num = 4;
      } else if (route.startsWith("/services/gpt-morph/")) {
        path = "/common/guide/model-morph/";
        num = 4;
      } else if (route.startsWith("/services/gpt-concept/concept")) {
        path = "/common/guide/ai-concept/";
        num = 4;
      } else if (route.startsWith("/services/gpt-concept/sketches")) {
        path = "/common/guide/concepter-sketches/";
        num = 2;
      } else if (route.startsWith("/services/global-trends/item-dashboard")) {
        path = "/common/guide/item-dashboard/";
        num = 2;
      } else if (route.startsWith("/services/global-trends/trend-dashboard")) {
        path = "/common/guide/trend-dashboard/";
        num = 2;
      } else if (route.startsWith("/services/global-trends/item-compare")) {
        path = "/common/guide/item-compare/";
        num = 4;
      } else if (route.startsWith("/services/global-trends/trend-compare")) {
        path = "/common/guide/trend-compare/";
        num = 4;
      } else if (route.startsWith("/services/global-trends/item-search")) {
        path = "/common/guide/item-search/";
        num = 2;
      } else if (route.startsWith("/services/global-trends/trend-search")) {
        path = "/common/guide/trend-search/";
        num = 2;
      }
      if (!path) {
        return;
      }
      this.dialogVisible = true;
      this.guideImages = [];
      let env = process.env.VUE_APP_ENV;
      if (!env) {
        env = "staging";
      }
      if (env === "production.kr") { env = "production" }
      for (let i = 1; i <= num; i++) {
        this.guideImages.push(ossRoot + "/" + env + path + i + ".jpg");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.guide {
  .cotent-dialog {
    text-align: left;
  }

  /deep/ .el-carousel__arrow {
    background-color: rgba(31, 45, 61, 0.81);
  }

  /deep/ .el-carousel__arrow:hover {
    background-color: rgba(31, 45, 61, 1);
  }

  /deep/ .el-carousel__indicators--outside button {
    background-color: #1f2d3d;
  }
}
</style>